import { useEffect } from 'react';


const HiddenScroll = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return null;
};

export default HiddenScroll;
